// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

main {
    margin-left: calc(250px + 1rem) !important;
    background-color: var(--bs-white);
}
.sidebar {
    position: fixed;
    height: 100%;
    width: 250px;
    top: 0;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}
.messages {
    max-height: calc(100vh - 170px);
    min-height: calc(100vh - 170px);
}

.add-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
